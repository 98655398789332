<template>
<div>
    <section id="knowledge-base-content">
        <b-row>
            <b-col :cols="4">
                <b-card no-body>
                    <b-card-header class="pb-50">
                        <h5>
                            Filtreleme
                        </h5>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Sınıf/Ders</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedLesson" :options="lessons" class="w-100" :getOptionLabel="option => option.class.name+' / '+option.lesson.name" :reduce="val => val.id" />
                            </b-col>

                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Alt Ders</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedChildLesson" :options="childLessons" class="w-100" :reduce="val => val.id" label="name" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Konu</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTopic" :options="topics" class="w-100" :reduce="val => val.id" label="name" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Video Grup</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideoGroup" :options="videoGroups" class="w-100" :reduce="val => val.id" label="name" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Test</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedTest" :options="tests" class="w-100" :reduce="val => val" label="title" />
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" md="12" class="mb-md-0 mb-2">
                                <label>Video</label>
                                <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" v-model="selectedVideo" :options="videos" class="w-100" :reduce="val => val" label="name" />
                            </b-col>
                        </b-row>
                       
                        <b-row>
                            <b-col cols="6" md="12" class="mb-md-0 mb-2 mt-2">
                                <b-button variant="success" @click="getTestData()" block>Ekle</b-button>
                            </b-col>
                        </b-row>
                        <b-overlay :show="loading" no-wrap>
                        </b-overlay>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col :cols="8">
                <app-collapse type="margin" accordion>
                    <app-collapse-item :isVisible="true" title="Seçilen Testler">
                        <b-row>
                            <b-col :cols="4" v-for="(test,index) in selectedHomeWorkData" :key="test.id">
                                <b-card :title="test.title" class="position-static text-center">
                                    <b-card-text>
                                        <b-button size="sm" variant="flat-danger" @click="()=>{$delete(selectedHomeWorkData,index)}">
                                            Testi Kaldır
                                        </b-button>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                      <app-collapse-item :isVisible="true" title="Seçilen Videolar">
                        <b-row>
                            <b-col :cols="4" v-for="(video,index) in selectedHomeWorkDataVideo" :key="video.id">
                                <b-card :title="video.name" class="position-static text-center">
                                    <b-card-text>
                                        <b-button size="sm" variant="flat-danger" @click="()=>{$delete(selectedHomeWorkDataVideo,index)}">
                                            Videoyu Kaldır
                                        </b-button>
                                    </b-card-text>
                                </b-card>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                    <app-collapse-item title="Öğrenciler">
                        <div class="card-transaction px-2 py-1">
                            <b-row>
                                <b-col :cols="4">
                                    <div class="transaction-item" @click="addStudent = true">
                                        <b-media no-body>
                                            <b-media-aside>
                                                <b-avatar rounded size="42" variant="light-success">
                                                    <feather-icon size="18" icon="PlusIcon" />
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body>
                                                <h6 class="transaction-title">Yeni Öğrenci Ekle</h6>
                                                <small>Ev Ödevini Atamak İstediğiniz öğrencileri ekleyin</small>
                                            </b-media-body>
                                        </b-media>
                                        <div class="font-weight-bolder text-danger"></div>
                                    </div>
                                </b-col>
                                <b-col :cols="4" v-for="(student,index) in selectedUser" :key="student.id">
                                    <div class="transaction-item">
                                        <b-media no-body>
                                            <b-media-aside>
                                                <b-avatar rounded size="42" variant="light-primary">
                                                    <feather-icon size="18" icon="UserIcon" />
                                                </b-avatar>
                                            </b-media-aside>
                                            <b-media-body>
                                                <h6 class="transaction-title">
                                                    {{ student.firstName }} {{ student.lastName }}
                                                </h6>
                                                <b-button size="sm" variant="flat-danger" class="text-danger" @click="()=>{$delete(selectedUser,index)}">
                                                    ÇIKART
                                                </b-button>
                                            </b-media-body>
                                        </b-media>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </app-collapse-item>
                    <app-collapse-item title="Kayıt Öncesi">
                        <b-row>
                            <b-col>
                                <label>Başlangıç Tarihi</label>
                                <flat-pickr v-model="startDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                            </b-col>
                            <b-col>
                                <label>Bitiş Tarihi</label>
                                <flat-pickr v-model="endDate" class="form-control" :config="{ enableTime: true,time_24hr:true,dateFormat: 'd-m-Y H:i',locale:'tr'}" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label>Açıklama</label>
                                <b-form-textarea name="firstName" id="name" type="text" v-model="definition" />
                            </b-col>
                            <b-col>
                                <h5>Not : Ödevin Adı ve Kodu seçilen testlere göre otomatik olarak verilecektir.</h5>
                                <b-button variant="success" @click="addHomeWorkData()" block>Ekle</b-button>
                            </b-col>
                        </b-row>
                    </app-collapse-item>
                </app-collapse>
            </b-col>
        </b-row>
    </section>
    <b-modal title="Öğrenci Ekle" ok-title="Ekle" size="xl" ok-only cancel-variant="outline-secondary" v-model="addStudent">
        <selected-student @AddUser="addUser" />
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </b-modal>
    <b-modal v-model="showLoading" centered hide-footer hide-header>
        <p class="text-center">
            <b>Ödev Oluşturuluyor!<br>Lütfen Bekleyin...</b>
        </p>
    </b-modal>
</div>
</template>

<script>
import {
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BProgress,
    BOverlay,
    BCardText
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from 'vue-select'
import SelectedStudent from './PageComponent/SelectedStudent.vue'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        flatPickr,
        SelectedStudent,
        vSelect,
        AppCollapse,
        AppCollapseItem,
        ToastificationContent,
        BCardHeader,
        BCardBody,
        BRow,
        BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BProgress,
        BOverlay,
        BCardText
    },
    data() {
        return {
            showLoading: false,
            definition: '',
            startDate: '',
            endDate: '',
            show: false,
            loading: false,
            selectedLesson: null,
            selectedChildLesson: null,
            selectedTopic: null,
            selectedVideoGroup: null,
            selectedTest: null,
            selectedVideo:null,
            users: [],
            lessons: [],
            childClass: [],
            childLessons: [],
            videoGroups: [],
            topics: [],
            tests: [],
            videos:[],
            filterText: '',
            filterRequest: {
                lessnId: '',
                childClassId: '',
                childLessonId: '',
                status: 5
            },
            selectedHomeWorkData: [],
            selectedHomeWorkDataVideo: [],
            addStudent: false,
            openDetailsPanel: false,
            details: [],
            selectedUser: [],
        }
    },
    created() {
        this.getLesson();
    },
    watch: {
        selectedLesson: function (val) {
            this.getChildLesson();
        },
        selectedChildLesson: function () {
            this.getTopics();
        },
        selectedTopic: function () {
            this.getVideoGroup();
        },
        selectedVideoGroup: function () {
            this.getTests();
            this.getVideos();
        }
    },
    methods: {
        addUser(users) {
            users.forEach(element => {
                if (this.selectedUser.filter(x => x.id == element.id).length == 0) {
                    this.selectedUser.push(element);
                }
            });
        },
        async getLesson() {
            var lesson = await this.$http.get("Teacher/Lessons");
            this.lessons = lesson.data.data;
        },

        async getChildLesson() {
            this.filterRequest.childLessonId = "";
            var data = this.lessons.filter(x => x.id == this.selectedLesson);
            if (data.length > 0) {
                data = data[0];
                var childLesson = await this.$http.get("Teacher/ChildLessons/" + data.lesson.id + "/" + data.class.id);
                this.childLessons = childLesson.data.data;
            }
        },

        async getTopics() {
            var topics = await this.$http.get("Teacher/Topics/" + this.selectedChildLesson);
            this.topics = topics.data.data;
        },

        async getVideoGroup() {
            var videoGroups = await this.$http.get("Teacher/VideoGroup/" + this.selectedTopic);
            this.videoGroups = videoGroups.data.data;
        },

        async getTests() {
            var tests = await this.$http.get("Teacher/Test/" + this.selectedVideoGroup);
            this.tests = tests.data.data;
        },

        async getVideos() {
            var tests = await this.$http.get("Teacher/Video/" + this.selectedVideoGroup);
            this.videos = tests.data.data;
        },

        async getTestData() {
            if (this.selectedTest != null) {
                this.selectedHomeWorkData.push(this.selectedTest);
            }
            if(this.selectedVideo !=null){
                this.selectedHomeWorkDataVideo.push(this.selectedVideo);
            }
        },
        addHomeWorkData() {
            this.showLoading = true;

            var req = new FormData();
            req.append("definition", this.definition);
            req.append("startDate", this.startDate);
            req.append("endDate", this.endDate);
            var index = 0;
            this.selectedUser.forEach(user => {
                req.append("userId[" + index + "]", user.id);
                index++;
            });

            index = 0;
            this.selectedHomeWorkData.forEach(test => {
                req.append("testId[" + index + "]", test.id);
                index++;
            });

            index = 0;
            this.selectedHomeWorkDataVideo.forEach(test => {
                req.append("videoId[" + index + "]", test.id);
                index++;
            });

            this.$http.post("Teacher/AddMultiHomeWork", req).then(data => {
                this.showLoading = false;
                this.$swal({
                    title: 'Ödevler Oluşturuldu!',
                    html: '<strong>5</strong> saniye içerisinde sayfa yenilenecek.',
                    icon: 'success',
                    timer: 5000,
                    timerProgressBar: true,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                }).then((resul) => {
                    location.reload();
                });
            }).catch(error => {
                this.showLoading = false;
            });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style><style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
